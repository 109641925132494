import React from 'react';
import { Link } from 'react-router-dom';
import { useAppContext } from '../../api/AppSettings';
import { useTranslation } from 'react-i18next';
import spinnerGif from '../../images/fancybox/fancybox_loading@2x.gif';


const Trainings = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }
    const { t, i18n } = useTranslation();
    const lang = i18n.language;
    const { apiSettings } = useAppContext();

    if (!apiSettings.settings || apiSettings.loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <img src={spinnerGif} alt="Loading..." />
            </div>
        );
    }
    const catTrainings = apiSettings.settings.catTrainings;

    return (
        <section className="pt-[120px] md:pt-[10px] pb-[100px] md:pb-[10px]">
            <div className="wraper antialiased">
                <div className="col-span-12 text-center">
                    <div className="mb-[60px] flex justify-center items-center gap-4">

                        <div className="h-[60px] w-[60px] border border-[#666] border-[rgba(192, 181, 150, .5)] rounded-full flex justify-center items-center">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.0" stroke="currentColor" className="size-10">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.042A8.967 8.967 0 0 0 6 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 0 1 6 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 0 1 6-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0 0 18 18a8.967 8.967 0 0 0-6 2.292m0-14.25v14.25" />
                            </svg>
                          
                        </div>
                        <h2 className="text-[36px] md:text-[26px] font-medium text-[#333] relative before:absolute before:content-[''] before:left-[50%] before:bottom-0 before:transform before:-translate-x-1/2 before:w-[60px] before:h-[3px] before:bg-[#C0B596]">
                            {t('Trainings')}
                        </h2>
                    </div>
                </div>

                {catTrainings.map((cat, Citem) => (
                    <div className="grid grid-cols-12 items-center gap-2" key={Citem}>
                        {Citem % 2 === 0 ? (
                            // Image à gauche, description à droite
                            <>
                                <div className="col-span-3 md:col-span-12 md:hidden">
                                    <div className="relative mb-[20px]">
                                        <div className="relative max-w-[320px] before:absolute before:bg-[#C0B596] before:w-full before:h-full before:content-[''] before:-z-10 before:left-[-20px] before:top-[-20px]">
                                            <img className="w-full" src={cat.image_path} alt={cat.name[lang]} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-span-9 md:col-span-12">
                                    <div className="col-span-12 mb-5">
                                        <div className="group flex sm:mx-[80px] col:mx-0">
                                            <div className="pl-[24px]">
                                                <h3 className="text-[#373737] text-[24px] col:text-[20px] font-medium pb-[10px] relative mb-[10px]
                                                before:absolute before:content-[''] before:left-0 before:bottom-0 before:w-[30px] before:h-[2px] before:bg-[#C0B596]">
                                                    <Link onClick={ClickHandler} to={`/cat-training/${cat.slug}`}>
                                                        {cat.name[lang]}
                                                    </Link>
                                                </h3>
                                                <p className="text-[#777] text-[16px] font-normal">
                                                    {cat.description[lang].length > 1300 ? (
                                                        <>
                                                            <span dangerouslySetInnerHTML={{ __html: cat.description[lang].substring(0, 1300) }}></span>
                                                            <Link className="text-[#C0B596]" onClick={ClickHandler} to={`/cat-training/${cat.slug}`}>{t('Read More')}</Link>
                                                        </>
                                                    ) : (
                                                        <span dangerouslySetInnerHTML={{ __html: cat.description[lang] }}></span>
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            // Description à gauche, image à droite
                            <>
                                <div className="col-span-9 md:col-span-12 mt-20 mr-10">
                                    <div className="col-span-12 mb-5">
                                        <div className="group flex sm:mx-[80px] col:mx-0">
                                            <div className="pl-[24px]">
                                                <h3 className="text-[#373737] text-[24px] col:text-[20px] font-medium pb-[10px] relative mb-[10px]
                                                before:absolute before:content-[''] before:left-0 before:bottom-0 before:w-[30px] before:h-[2px] before:bg-[#C0B596]">
                                                    <Link onClick={ClickHandler} to={`/cat-training/${cat.slug}`}>                                                   
                                                        {cat.name[lang]}
                                                    </Link>
                                                </h3>
                                                <p className="text-[#777] text-[16px] font-normal">
                                                    {cat.description[lang].length > 1300 ? (
                                                        <>
                                                            <span dangerouslySetInnerHTML={{ __html: cat.description[lang].substring(0, 1300) }}></span>
                                                            <Link className="text-[#C0B596]" onClick={ClickHandler} to={`/cat-training/${cat.slug}`}>{t('Read More')}</Link>
                                                        </>
                                                    ) : (
                                                        <span dangerouslySetInnerHTML={{ __html: cat.description[lang] }}></span>
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-span-3 md:col-span-12 md:hidden  mt-20">
                                    <div className="relative mb-[20px]">
                                        <div className="relative max-w-[520px] before:absolute before:bg-[#C0B596] before:w-full before:h-full before:content-[''] before:-z-10 before:left-[-20px] before:top-[-20px]">
                                            <img className="w-full" src={cat.image_path} alt={cat.name[lang]} />
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                ))}
            </div>
        </section>
    );

}

export default Trainings;
