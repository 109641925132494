import React, { Fragment } from 'react';

import Navbar2 from '../../components/Navbar2/Navbar2';
import PageTitle from '../../components/pagetitle/PageTitle';
import Scrollbar from '../../components/scrollbar/scrollbar';
import { useParams, Link } from 'react-router-dom';
import Footer from '../../components/footer/Footer';
import Sidebar from './Sidebar';
import TrainingsAreeaRequest from '../../components/TrainingsAreeaRequest/TrainingsAreeaRequest';
import { useTranslation } from 'react-i18next';
import { useAppContext } from '../../api/AppSettings';
import spinnerGif from '../../images/fancybox/fancybox_loading@2x.gif';

import Gallery from '../../components/gallery/Gallery';


const CategoryTrainingSinglePage = (props) => {
    const { slug } = useParams();
    const { i18n } = useTranslation();
    const { t } = useTranslation();
    const { apiSettings } = useAppContext();
    if (!apiSettings.settings || apiSettings.loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <img src={spinnerGif} alt="Loading..." />
            </div>
        );
    }
    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }
    const info = apiSettings.settings;
    const lang = i18n.language;
    const catTrainings = info.catTrainings;

    const CateroryTraining = catTrainings.find(item => item.slug === slug);
    const trainingsInCat = CateroryTraining.trainings_active;
    //  console.log('servicesInCat', servicesInCat);


    return (
        <Fragment>
            <Navbar2 />
            <PageTitle pageTitle={CateroryTraining.name[lang]} pagesub={CateroryTraining.name[lang]} image_path={CateroryTraining.image_path} />
            <div className="py-[30px] md:py-[2px]">
                <div className="wraper">
                    <div className="grid grid-cols-12 gap-3">
                        <Sidebar trainings={trainingsInCat} lang={lang} slug={slug} />
                        <div className="col-span-8 md:col-span-12 order-2 md:order-1">
                            <div className="pt-[5px]">
                                <h2 className="text-[24px] text-[#282e3f] mb-[10px]">{CateroryTraining.name[lang]}</h2>

                                <p className="text-[16px] leading-[30px] py-[20px] mb-[16px]">
                                    <span dangerouslySetInnerHTML={{ __html: CateroryTraining.description[lang] }}></span>
                                </p>

                            </div>
                            <div className="pt-[10px]">
                                <div className="col-span-8 md:col-span-12 order-2 md:order-1">
                                    <h2 className="md:text-[20px] text-[24px] text-[#282e3f] mb-[8px] block">
                                        <span className="mt-1 relative whitespace-nowrap text-[#333] ">
                                            <svg aria-hidden="true" viewBox="0 0 418 42" className="absolute top-2/3 left-0 h-[0.58em] w-full fill-[#C0B596]" preserveAspectRatio="none">
                                                <path d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z"></path>
                                            </svg>
                                            <span className="relative mt-3">
                                                {t('Courses')} :
                                            </span>
                                        </span>
                                    </h2>
                                    {trainingsInCat.map((training, item) => (
                                        <div key={item} className=" pt-[5px] mb-5 text-[14px] text-[#333]">

                                            <Link onClick={ClickHandler} to={`/traning/${slug}/${training.id}`}
                                                className="flex flex-row items-inline gap-2 mb-2 transition ease-in-out duration-300 hover:text-[#C0B596]">
                                                <span className="h-[30px] w-[30px] border border-[#666] border-[rgba(192, 181, 150, .5)] rounded-full flex justify-center items-center">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.0" stroke="currentColor" className="size-6">
                                                        <path strokeLinejoin="round" d="M12 6.042A8.967 8.967 0 0 0 6 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 0 1 6 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 0 1 6-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0 0 18 18a8.967 8.967 0 0 0-6 2.292m0-14.25v14.25" />
                                                    </svg>
                                                </span>
                                                {training.name[lang]}
                                            </Link>
                                            <p className="text-[#777] text-[14px] font-normal">
                                                {training.infos_important[lang].length > 250 ? (
                                                    <>
                                                        <span dangerouslySetInnerHTML={{ __html: training.infos_important[lang].substring(0, 250) + '.....'}}></span>

                                                    </>
                                                ) : (
                                                    <span dangerouslySetInnerHTML={{ __html: training.infos_important[lang] }}></span>
                                                )}
                                                <Link className="text-[#C0B596]" onClick={ClickHandler} to={`/traning/${slug}/${training.id}`}>{t('Read More')}</Link>
                                            </p>

                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
             
            </div>
            <Gallery items={trainingsInCat} lang={lang} />
            <TrainingsAreeaRequest />
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default CategoryTrainingSinglePage;
