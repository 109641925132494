import React, { Fragment } from 'react';
import Navbar2 from '../../components/Navbar2/Navbar2'
import Hero from '../../components/hero/hero';
import Features from '../../components/Features/Features';
import About from '../../components/about/about';
import Scrollbar from '../../components/scrollbar/scrollbar';
import CatServices from '../../components/CatServices';
import ConsultingArea from '../../components/ConsultingArea/ConsultingArea';
import Newsletter from '../../components/Newsletter/Newsletter';
import Footer from '../../components/footer/Footer';
import { useAppContext } from '../../api/AppSettings';
import spinnerGif from '../../images/fancybox/fancybox_loading@2x.gif';
import Trainings from '../../components/Trainings';

const HomePage = () => {
    const {  apiSettings } = useAppContext();
    if (!apiSettings.settings || apiSettings.loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <img src={spinnerGif} alt="Loading..." />
            </div>
        );
    }
    // console.log( apiSettings );

    const slides  = apiSettings.settings.slides; 
    const info = apiSettings.settings;
  
    return (
       <Fragment>
        <Navbar2 hclass={'wpo-header-style-1'} topbarNone={'topbar-none'} /> 
        <Hero slides ={slides}/>
        <Features/>
        <About info={info}/>
        <ConsultingArea />
        <CatServices/>
        <Newsletter />
        <Trainings />
        <Footer/>
        <Scrollbar /> 
    </Fragment>

    )
};
export default HomePage;