import React, { useState, useEffect, useRef, useMemo } from "react";
import { useTranslation } from "react-i18next";

const Gallery = (props) => {
    const { items, lang } = props;
    const urlImg = process.env.REACT_APP_API_STORAGE;
    const { t } = useTranslation();

    const [filteredMedia, setFilteredMedia] = useState([]);
    const [currentVariant, setCurrentVariant] = useState("all");
    const [selectedMedia, setSelectedMedia] = useState(null);
    const [currentMediaIndex, setCurrentMediaIndex] = useState(0);
    const galleryRef = useRef(null);

    const media = useMemo(() => {
        const result = {};
        if (items) {
            items.forEach((variant) => {
                const variantId = variant.id;
                const imgs = (variant.images || []).map((image) => ({ name: variant.name.en, type: "image", src: urlImg + image }));
                const vids = (variant.video || []).map((video) => ({ name: variant.name.en + 'v', type: "video", src: urlImg + video }));
                result[variantId] = [...imgs, ...vids];
            });
        }
        return result;
    }, [items, urlImg]);

    const allMedia = useMemo(() => Object.values(media).flat(), [media]);

    useEffect(() => {
        setFilteredMedia(allMedia);
    }, [allMedia]);

    const handleFilterChange = (variantId) => {
        setCurrentVariant(variantId);
        setFilteredMedia(variantId === "all" ? allMedia : media[variantId] || []);
    };

    const handleMediaClick = (media, index) => {
        setSelectedMedia(media);
        setCurrentMediaIndex(index);
    };

    const handleNextMedia = () => {
        const nextIndex = (currentMediaIndex + 1) % filteredMedia.length;
        setCurrentMediaIndex(nextIndex);
        setSelectedMedia(filteredMedia[nextIndex]);
    };

    const handlePrevMedia = () => {
        const prevIndex = (currentMediaIndex - 1 + filteredMedia.length) % filteredMedia.length;
        setCurrentMediaIndex(prevIndex);
        setSelectedMedia(filteredMedia[prevIndex]);
    };

    const handleCloseMedia = () => {
        setSelectedMedia(null);
    };

    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            handleCloseMedia();
        }
    };

    // ✅ Close on ESC key
    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === "Escape") {
                handleCloseMedia();
            }
        };
        document.addEventListener("keydown", handleKeyDown);
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, []);

    const renderVariantButtons = () => {
        if (!items) return null;

        return (
            <>
                {items.length > 1 && (
                    <button
                        onClick={() => handleFilterChange("all")}
                        className={`group px-4 py-2 text-md font-medium rounded ${currentVariant === "all" ? "bg-[#cbbc99] text-white font-bold" : "bg-white text-[#C0B596] font-bold"}`}
                    >
                        {t("All")}
                        <div className="bg-[#C0B596] h-[3px] w-0 group-hover:w-full transition-all duration-500"></div>
                    </button>
                )}

                {items.map((variant) => {
                    const fullName = variant.name?.[lang] || variant.name?.lang || t("Unnamed Service");
                    const truncatedName = fullName.length > 15 ? fullName.slice(0, 15) + "..." : fullName;

                    return (
                        <button
                            key={variant.id}
                            onClick={() => handleFilterChange(variant.id)}
                            title={fullName}
                            className={`group px-4 py-2 text-md font-medium rounded ${currentVariant === variant.id ? "bg-[#cbbc99] text-white font-bold" : "bg-white text-[#C0B596] font-bold"}`}
                        >
                            {truncatedName}
                            <div className="bg-[#C0B596] h-[3px] w-0 group-hover:w-full transition-all duration-500"></div>
                        </button>
                    );
                })}
            </>
        );
    };

    return (
        <div className="wraper w-full bg-white pb-20">
            <section className="px-4 py-4 mx-auto">
                <div className="w-full mx-auto text-left md:w-11/12 xl:w-9/12 md:text-center">
                    <div className="flex items-center justify-center py-4 md:py-8 flex-wrap gap-2">
                        {renderVariantButtons()}
                    </div>
                </div>
            </section>

            <section className="w-full h-full select-none">
                <div className="max-w-8xl md:w-full px-20 md:px-1">
                    <ul ref={galleryRef} id="gallery" className="grid grid-cols-4 md:grid-cols-2 gap-2">
                        {filteredMedia.map((media, idx) => (
                            <li key={idx}>
                                <a
                                    href="#!"
                                    onClick={() => handleMediaClick(media, idx)}
                                    className="group relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40 min-h-[16vw] md:min-h-[40vw]"
                                >
                                    {media.type === "image" ? (
                                        <img
                                            src={media.src}
                                            alt={media.name + '_' + idx}
                                            className="absolute inset-0 h-full w-full object-cover group-hover:scale-150 transition-transform duration-500 ease-in-out"
                                        />
                                    ) : (
                                        <div className="absolute inset-0 h-full w-full bg-black/20">
                                            <video
                                                src={media.src}
                                                className="h-full w-full object-cover"
                                                muted
                                                autoPlay
                                                loop
                                            />
                                            <div className="absolute inset-0 flex items-center justify-center">
                                                <svg
                                                    className="w-14 h-14 text-[#C0B596] opacity-50 hover:opacity-100 transition-opacity"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                    strokeWidth="1.5"
                                                >
                                                    <circle cx="12" cy="12" r="10" stroke="currentColor" />
                                                    <path fill="currentColor" d="M10 8l6 4-6 4V8z" />
                                                </svg>
                                            </div>
                                            <div className="absolute bottom-2 left-2 bg-black bg-opacity-70 text-white text-xs px-2 py-1 rounded">Video</div>
                                        </div>
                                    )}
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>

                {selectedMedia && (
                    <div
                        onClick={handleOverlayClick}
                        className="z-[999] fixed inset-0 flex justify-center items-center bg-black backdrop-blur bg-opacity-35 py-5"
                    >
                        <div className="w-full flex justify-center items-center px-4 pointer-events-none">
                            <div onClick={(e) => e.stopPropagation()} className="absolute top-8 right-8 z-1050 pointer-events-auto">
                                <button
                                    onClick={handleCloseMedia}
                                    className="text-white bg-black/60 hover:bg-[#C0B596] rounded-full w-10 h-10 flex items-center justify-center"
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="1.5">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </button>
                            </div>

                            <div
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handlePrevMedia();
                                }}
                                className="absolute md:ml-[2%] ml-[15%] border border-white z-40 top-1/2 left-0 flex items-center justify-center text-white rounded-full cursor-pointer bg-[#4b424da8] w-14 h-14 hover:bg-[#C0B596] pointer-events-auto"
                            >
                                <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                                </svg>
                            </div>

                            <div onClick={(e) => e.stopPropagation()} className="w-full flex justify-center items-center pointer-events-auto">
                                {selectedMedia.type === "image" ? (
                                    <div className="w-full max-w-full md:max-w-[80%] sm:max-w-[70%] max-h-full flex items-center justify-center">
                                        <img
                                            src={selectedMedia.src}
                                            alt="selected"
                                            className="object-contain max-h-full max-w-full object-center rounded-lg"
                                        />
                                    </div>
                                ) : (
                                    <div className="w-full max-w-[27vw] md:max-w-[26vw] sm:max-w-[80vw] max-h-[690vh] flex items-center justify-center">
                                        <video
                                            src={selectedMedia.src}
                                            controls
                                            controlsList="nodownload"
                                            autoPlay
                                            className="object-contain max-h-full max-w-full object-center rounded-lg"
                                        />
                                    </div>
                                )}
                            </div>

                            <div
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleNextMedia();
                                }}
                                className="absolute md:mr-[2%] mr-[15%] border border-white z-40 top-1/2 right-0 flex items-center justify-center text-white rounded-full cursor-pointer bg-[#4b424da8] w-14 h-14 hover:bg-[#C0B596] pointer-events-auto"
                            >
                                <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                                </svg>
                            </div>
                        </div>
                    </div>
                )}
            </section>
        </div>
    );
};

export default Gallery;
