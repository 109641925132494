import { useState } from 'react';
import SimpleReactValidator from 'simple-react-validator';
import { useTranslation } from 'react-i18next';

export const useForm = (lang) => {
    const { t } = useTranslation();
    const [forms, setForms] = useState({
        name: '',
        email: '',
        subject: '',
        phone: '',
        message: '',
        captchaToken: '',
        lang: lang,
    });

    const [validator] = useState(new SimpleReactValidator({
        className: 'errorMessage',
        messages: {
            required: t('errors.required'),
            alpha_space: t('errors.alpha_space'),
            email: t('errors.invalid_email'),
            phone: t('errors.invalid_phone')
        },
    }));

    const changeHandler = (e) => {
        setForms({ ...forms, [e.target.name]: e.target.value })

        if (validator.allValid()) {
            validator.hideMessages();
        } else {
            validator.showMessages();
        }
    };

    const submitHandler = async (e, customFormData = null) => {
        e.preventDefault();
    
        const dataToSend = customFormData || forms;
    
        if (validator.allValid()) {
            validator.hideMessages();
      
            try {
                const apiUrl = process.env.REACT_APP_API_URL + '/client-request';
                const response = await fetch(apiUrl, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(dataToSend)
                });
    
                if (response.ok) {
                    document.getElementById('submit-message-contact').classList.remove('hidden');
                    document.getElementById('submit-message-contact').innerHTML = t('Thank you for contacting us');
                    console.log('Formulaire soumis avec succès !');
                    return true;
                } else {
                  
                    document.getElementById('submit-message-contact').classList.remove('hidden');
                    document.getElementById('submit-message-contact').innerHTML = t('An error occurred while submitting the form');
                    console.error('Erreur lors de la soumission du formulaire !');
                    return false;
                }

             
            } catch (error) {
                document.getElementById('submit-message-contact').classList.remove('hidden');
                document.getElementById('submit-message-contact').innerHTML = t('An error occurred while submitting the form');
                console.error('Erreur lors de la soumission du formulaire :', error);
                return false;
            }
        } else {
            validator.showMessages();
        }
        return true;
    };
    
    return { forms, validator, changeHandler, submitHandler };
};
