import React, { useState } from 'react';
import { useForm } from '../../api/formUtils';
import { useTranslation } from 'react-i18next';
// import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
// import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
// Contact form from the contact page
const ContactForm = (props) => {
    const { t } = useTranslation();
    const catServices = props.catServices;
    const lang = props.lang;

    const { forms, validator, changeHandler, submitHandler } = useForm(lang);
    // const { executeRecaptcha } = useGoogleReCaptcha();
    // const [captchaToken, setCaptchaToken] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        // if (!executeRecaptcha) {
        //     console.log("ReCAPTCHA not loaded");
        //     return;
        // }

        try {
            // Exécuter reCAPTCHA
            setIsSubmitting(true);
            // const token = await executeRecaptcha('contact_form');
            // setCaptchaToken(token);
            // if (!token) {
            //     alert("Please complete the CAPTCHA");
            //     setIsSubmitting(false); // Réactiver le bouton si CAPTCHA échoue
            //     return;
            // }
            
            // forms.captchaToken = token;
            // Préparer les données du formulaire avec le token reCAPTCHA
            const formData = {
                ...forms,

            };
            console.log('Form data being sent:', formData);
            await submitHandler(e, formData)
            .catch((err) => {
              console.error("Erreur attrapée dans ContactForm :", err ?? '[Aucune erreur détectée]');
            });
        } catch (error) {
            console.error('ReCAPTCHA error:', error);
            alert("Failed to execute reCAPTCHA. Please try again.");
            return;
        } finally {
            setIsSubmitting(false); // Réactiver le bouton après la réponse du serveur
        }
    };

    return (
        // <GoogleReCaptchaProvider
        //     reCaptchaKey='6Le9hjgqAAAAAIiks0H7ZrY300tBth6dc8K5LNgJ'>
            <form method="post" className="contact-validation-active mx-[-15px] overflow-hidden" id="contact-form-main" onSubmit={handleSubmit}>
                <div className="w-[calc(50%-30px)] float-left mx-[15px] mb-[25px] col:float-none col:w-[calc(100%-25px)]">
                    <input
                        className='form-control w-full bg-transparent h-[50px] border border-[#ebebeb] text-[#666] transition-all pl-[25px] focus:outline-0 focus:shadow-none focus:border-[#C0B596] focus:bg-transparent'
                        value={forms.name}
                        type="text"
                        name="name"
                        onChange={(e) => changeHandler(e)}
                        placeholder={t('Your Name')} />
                    {validator.message('name', forms.name, 'required|alpha_space')}
                </div>
                <div className="w-[calc(50%-30px)] float-left mx-[15px] mb-[25px] col:float-none col:w-[calc(100%-25px)]">
                    <input
                        className='form-control  w-full bg-transparent h-[50px] border border-[#ebebeb] text-[#666] transition-all pl-[25px] focus:outline-0 focus:shadow-none focus:border-[#C0B596] focus:bg-transparent'
                        value={forms.email}
                        type="email"
                        name="email"
                        onChange={(e) => changeHandler(e)}
                        placeholder={t('Your Email')} />
                    {validator.message('email', forms.email, 'required|email')}
                </div>
                <div className="w-[calc(50%-30px)] float-left mx-[15px] mb-[25px] col:float-none col:w-[calc(100%-25px)]">
                    <input
                        className='form-control  w-full bg-transparent h-[50px] border border-[#ebebeb] text-[#666] transition-all pl-[25px] focus:outline-0 focus:shadow-none focus:border-[#C0B596] focus:bg-transparent'
                        value={forms.phone}
                        type="text"
                        name="phone"
                        onChange={(e) => changeHandler(e)}
                        placeholder={t('Your Phone')} />
                    {validator.message('phone', forms.phone, 'required|phone')}
                </div>
                <div className="w-[calc(50%-30px)] float-left mx-[15px] mb-[25px] col:float-none col:w-[calc(100%-25px)]">
                    <select
                        className='form-control w-full bg-transparent h-[50px] border border-[#ebebeb] text-[#666] transition-all pl-[25px] focus:outline-0 focus:shadow-none focus:border-[#C0B596] focus:bg-transparent'
                        onChange={(e) => changeHandler(e)}
                        value={forms.subject}
                        name="subject">
                       
                        {catServices.map((cat, item) => (
                            <option key={item} >{cat.name[lang]}</option>
                        ))}
                          <option key="trainings" >{t('Trainings')}</option>
                      
                    </select>
                    {validator.message('subject', forms.subject, 'required')}
                </div>
                <div className="w-[calc-(100%-25px)] mb-[25px] mx-[15px]">
                    <textarea
                        className='form-control  w-full bg-transparent h-[180px] border border-[#ebebeb] text-[#666] transition-all pt-[15px] pl-[25px] focus:outline-0 focus:shadow-none focus:border-[#C0B596] focus:bg-transparent'
                        onChange={(e) => changeHandler(e)}
                        value={forms.message}
                        name="message"
                        placeholder={t('Your Message')}>
                    </textarea>
                    {validator.message('message', forms.message, 'required')}
                </div>
                <div className="text-center w-full mb-[10px]">
                    <button
                        type="submit"
                        className={`bg-[#C0B596] text-[#fff] inline-block py-[12px] px-[22px] border border-[#666] rounded-[55px] capitalize transition-all hover:bg-[#666] hover:border-transparent ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''}`}
                        disabled={isSubmitting} // Désactive le bouton si isSubmitting est true
                    >
                        {isSubmitting ? t('Submitting...') : t('Contact Us')}
                    </button>
                </div>
                <div id="submit-message-contact" className='hidden mt-3 text-[#CBB26A] font-bold text-center'> </div>
            </form>
        // </GoogleReCaptchaProvider>
    );
};

export default ContactForm;
