import React from 'react';
import freeConsultingImg from '../../images/contact/freeCosulting.jpeg';
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
const Sidebar = (props) => {
    
    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }
  const trainings = props.trainings;
  const lang = props.lang;
  const slug = props.slug;
  const{ t } = useTranslation();
    return (
        // before:bg-[rgba(0,0,0,.65)]
        <div className="col-span-3 md:col-span-6 sm:col-span-8  col:col-span-12  order-1 md:order-2">
            <div className="pr-[40px] lg:pr-0 md:mt-[40px]">
                <div className="relative z-10 before:z-10 before:absolute before:contnent-['']  before:top-0 before:left-0 before:w-full before:h-full ">
                    <img className="w-full bg-[#1d3a47] " src={freeConsultingImg} alt="" />
                    <div className="absolute top-0 left-0 w-full h-full flex justify-center flex-col text-center px-[40px] pb-[20px] z-20">                    
                        <div className="btn-style mt-[120px] ">
                            <Link className="w-full rounded-lg bg-[#4B424D] cursor-pointer  font-bold text-white px-[38px] py-[8px]  
                      capitalize inline-block mt-[6px] transition ease-in-out duration-300 hover:bg-[#4b424da8]
                      col:mb-[5px] col:mt-[15px] col:text-[18px] col:px-[18px] col:py-[2px] " to="/contact">{t('Contact us now')}</Link>
                        </div>
                    </div>
                </div>
                <div className="bg-[#f5f5f5] mt-[60px]">
                    <div className="bg-[#ddd] py-[10px] px-[20px] relative  
                  before:z-10 before:absolute before:contnent-[''] before:border-[15px] before:border-transparent 
                  before:border-t-[13px] before:border-t-[rgba(0,0,0,.1)]
                   before:top-full before:left-[30px] ">
                        <h3 className="text-[24px] text-[#282e3f]">{t('Trainings')}</h3>
                    </div>
                    <div className="pt-[15px] px-[30px] pb-[20px]">
                        <ul>
                            {trainings.map((training, item) => (
                                <li className="border-b border-[#ddd]" key={item}>
                                    <Link onClick={ClickHandler} to={`/traning/${slug}/${training.id}`} 
                                    className="text-[15px] pt-[15px] pb-[10px] text-[#333] block transition-all ease-in-out hover:text-[#C0B596]">
                                        {training.name[lang]}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
};
export default Sidebar;

