import React from 'react'
import { Link } from 'react-router-dom'
import { useAppContext } from '../../api/AppSettings';
import { useTranslation } from 'react-i18next';
import spinnerGif from '../../images/fancybox/fancybox_loading@2x.gif';

const PageTitle = (props) => {
    const { apiSettings } = useAppContext();
    const { t } = useTranslation();
    if (!apiSettings.settings || apiSettings.loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <img src={spinnerGif} alt="Loading..." />
            </div>
        );
    }

    const info = apiSettings.settings;
    let bgImage = info.image_about_path;
    if (props.image_path) {
        bgImage = props.image_path;
    }


    return (

        <section className="page-title min-h-[450px] sm:min-h-[300px] relative flex justify-start  items-center
            z-30  bg-no-repeat bg-center bg-cover text-left"
            style={{ backgroundImage: `url(${bgImage})` }}   >


            <div className="wraper antialiased">
                <div className="w-[100%] ml-auto text-left pt-[130px] md:pt-[0] sm:pt-[0]">
                    <div className=" flex flex-inline w-full">

                        <h2 className="md:text-[20px] text-[30px] flex w-[90%] justify-center text-center text-white leading-[60px] mt-[-10px] mb-[20px] font-black sm:leading-[35px] sm:mb-[10px]">

                            <span className="bg-gradient-to-r from-[#fff5d8]  to-[#d7c9a0] bg-clip-text text-transparent
                                decoration-[#d7c9a0]  shadow-orange-950">
                                {t(props.pageTitle)}
                            </span>
                        </h2>
                 
                    </div>



                    <ol className="pl-0 mb-0 text-center">
                        <li className="inline-block  py-0 px-[5px] text-white relative  text-[18px] font-base-font md:text-[18px] after:content-['/'] after:absolute after:right-[-5px]">
                            <Link className="text-white  mr-1 transition-all hover:text-[#cbbc99]" to="/">{t('Home')}</Link>
                        </li>
                        <li className="inline-block  px-[5px] text-[#fff5d8] text-[18px] font-base-font md:text-[18px]">
                            {
                                props.link ? (
                                    <Link className="ml-1  transition-all hover:text-[#cbbc99]" to={props.link}>
                                        {t(props.pagesub)}
                                    </Link>
                                ) : (
                                    <span className="ml-1 transition-all hover:text-[#cbbc99]">
                                        {t(props.pagesub)}
                                    </span>
                                )
                            }
                        </li>
                    </ol> 
                </div>
            </div>
        </section>
    )
}


export default PageTitle;