import React from 'react';
import { Link } from 'react-router-dom';
import SideBarContactUs from '../../components/SideBarContactUs/SideBarContactUs';

const Sidebar = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }
    const slug = props.slug;
    const lang = props.lang;
    const id = props.id;
    const catTrainings = props.catTrainings;
    const cateroryTraining = catTrainings.find(item => item.slug === slug);
    const trainings = cateroryTraining.trainings_active;
    return (
        <div>
            <div className="pl-[40px] lg:pl-0 md:hidden mb-8">
                <SideBarContactUs />
            </div>


            <div className="pl-[40px] lg:pl-0 md:mt-8">
                <div className="bg-[#f5f5f5]">
                    <div className="bg-[#ddd] py-[10px] px-[20px] relative  
                        before:z-10 before:absolute before:contnent-[''] before:border-[15px] before:border-transparent 
                        before:border-t-[13px] before:border-t-[rgba(0,0,0,.1)]
                        before:top-full before:left-[30px] ">
                        <h3 className="text-[14px] text-[#282e3f]">
                            <Link
                                onClick={ClickHandler}
                                to={`/cat-training/${slug}`}
                                className={`block transition-all ease-in-out hover:text-[#C0B596]`}>
                                {cateroryTraining.name[lang]}
                            </Link>

                        </h3>
                    </div>
                    <div className="pt-[15px] px-[30px] pb-[20px]">
                        <ul>
                            {trainings.map((traning, item) => (
                                <li className="border-b border-[#ddd]" key={item}>
                                    <Link
                                        onClick={ClickHandler}
                                        to={`/traning/${slug}/${traning.id}`}
                                        className={`text-[15px] pt-[15px] pb-[10px] 
                                        ${traning.id == id ? 'text-[#C0B596]' : 'text-[#333]'
                                            } block transition-all ease-in-out hover:text-[#C0B596]`}>
                                        {traning.name[lang]}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>

    )
};
export default Sidebar;

