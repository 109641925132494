import React, { Fragment } from 'react';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import { useParams } from 'react-router-dom'
import Footer from '../../components/footer/Footer';
import Sidebar from './Sidebar';
import ConsultingArea from '../../components/ConsultingArea/ConsultingArea';

import Navbar2 from '../../components/Navbar2/Navbar2';
import { useTranslation } from 'react-i18next';
import { useAppContext } from '../../api/AppSettings';
import spinnerGif from '../../images/fancybox/fancybox_loading@2x.gif';


const TrainingSinglePage = (props) => {
    const { slug, id } = useParams()
    const { apiSettings } = useAppContext();
    const { i18n } = useTranslation();
    if (!apiSettings.settings || apiSettings.loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <img src={spinnerGif} alt="Loading..." />
            </div>
        );
    }
    const info = apiSettings.settings;
    const lang = i18n.language;
    const catTrainings = info.catTrainings;
    console.log('catTrainings ',catTrainings );
    const cateroryTraining= catTrainings.find(item => item.slug === slug);
    const trainigsInCat = cateroryTraining.trainings_active;
    const training = trainigsInCat.find(item => parseInt(item.id) == id);
    const traning_image_path = cateroryTraining.image_path;


     console.log('traning_image_path',traning_image_path);

    return (
        <Fragment>
            <Navbar2 />

            <PageTitle pageTitle={training.name[lang]} pagesub={cateroryTraining.name[lang]} link={`/cat-training/${slug}`} image_path={traning_image_path} />

            <div className="py-[30px] md:py-[2px]">
                <div className="wraper">
                    <div className="grid grid-cols-12 gap-3">
                        <div className="col-span-9 md:col-span-12 order-1">
                            <div className="">
                                <h2 className="md:text-[18px] text-[24px] ">
                                    <span className="wrap text-[#333] ">
                                        <span className="">
                                            {training.name[lang]}
                                        </span>
                                    </span>
                                </h2>
                                <p className="text-[16px] leading-[30px] py-[10px] mb-[6px]">
                                    <span dangerouslySetInnerHTML={{ __html: training.infos_important[lang] }}></span>
                                </p>
                                <p className="text-[16px] leading-[30px] mb-[16px]">
                                    <span dangerouslySetInnerHTML={{ __html: training.description[lang] }}></span>
                                </p>
                            </div>

                        </div>
                        <div className="md:hidden col-span-3  col:col-span-12 order-2  mb-4 ">
                             <Sidebar lang={lang} slug={slug} id={id} catTrainings={catTrainings} />
                        </div>
                    </div>
                

                </div>
            </div>
            <ConsultingArea />               
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default TrainingSinglePage;
